import React from 'react';
import LoginPPR from '../../components/LoginPPR/LoginPPR';
import PPR from '../../components/PPR/PPR';
import './ParticipacaoRemunerada.css';
import Footer from '../../components/Footer/Footer';
import ProfilePPR from '../../components/ProfilePPR/ProfilePPR';
import { ToastContainer } from 'react-toastify';
import PPRDescription from '../../components/PPRDescription/PPRDescription';
import PPRCard from '../../components/PPRCard/PPRCard';
import PPRCard2 from '../../components/PPRCard2/PPRCard2';

function ParticipacaoRemunerada() {
  return (
    <section className='flex flex-col absolute h-screen min-h-full w-full'>
      <div className="relative h-sm:mb-96" id='login'>
        <div className="flex w-full z-10 pt-9 pb-9 h-lg:pb-32 h-lg:pt-32 absolute">
          <div className="mx-auto h-sm:hidden">
            <div className='mt-4 w-64 h-lg:w-auto'>
              <span className='text-3xl font-extrabold font-raleway text-white'>Conhece alguém que <br /> possui um consórcio <br /> cancelado?</span>
            </div>
            <div className='mt-4'>
              <span className='text-base text-white font-bold'>Você pode ser remunerado por <br /> essa indicação.</span>
            </div>
            <div className='mt-4'>
              <span className='text-sm text-white'>Com a parceria remunerada da Objetiva você  <br /> indica e <span className='font-bold'>ganha 6%</span> do valor da negociação.</span>
            </div>
          </div>
          <div className="mx-auto my-auto h-sm:hidden">
          </div>
          <div className="mx-auto my-auto h-sm:mt-20">
            <LoginPPR />
          </div>
        </div>
        <img src="ppr.png" alt="logo" className='z-0 top-0 left-0 w-full' />
      </div>
      <React.Fragment>
        <ProfilePPR />
        <PPR />
        <ToastContainer autoClose={1500} position="bottom-center" />
      </React.Fragment>
      <div className="flex w-full z-0 pb-44 h-sm:pb-60">
        <div className="flex w-full z-10 pt-9 pb-9  h-lg:pb-40 h-lg:pt-40 absolute top-96" style={{ top: '360px' }}>
          <PPRDescription />
        </div>
      </div>
      <div className="flex w-full z-0 pt-9 pb-9">
        <PPRCard
          imageSrc="ppr_card1.png"
          mainTitle="Quanto você ganha?"
          description1="O PROGRAMA DE PARCERIA REMUNERADA criado pela Objetiva busca compensar financeiramente a indicação de consorciados desistentes ou excluídos do consórcio que tenham interesse de antecipar a restituição dos valores pagos em cota de consórcio cancelada, vez que eles, em regra, só recebem esses valores no final do plano."
          description2="A compensação financeira é de 6% (seis por cento) sobre o valor da negociação, ou seja, sobre o valor que for pago pela compra da cota cancelada. Esse valor é facilmente apurado na calculadora disponível no nosso site, afastando qualquer dúvida quanto ao valor da negociação."
            />
      </div>
      <div className="flex w-full z-0 pt-9 pb-9">
        <PPRCard2
          imageSrc="ppr_card2.png"
          mainTitle="Nosso diferencial"
          description1="O grande diferencial competitivo frente a concorrência está no fato de que a negociação é feita sem intermediários – apenas por indicação – e segue rigorosamente o valor apurado na calculadora elaborada especificamente para dar transparência e equilíbrio financeiro à negociação da cota cancelada."
          description2="O(a) Parceiro(a) não precisa ter grandes conhecimentos sobre o sistema de consórcio, mas o suficiente para identificar no extrato do consorciado indicado os seguintes dados: valor do crédito, percentual amortizado (pago) na cota e a data prevista para o encerramento do grupo, para poder lançar na calculadora e ter a nossa proposta de compra."
            />
      </div>
      <div className="flex w-full z-0 pt-9 pb-9">
        <PPRCard
          imageSrc="ppr_card3.png"
          mainTitle="Sem burocracia"
          description1="Ainda que a pessoa interessada em participar da parceria esteja vinculada a bancos ou administradoras de consórcios, não precisa se desligar das suas atividades e muito menos comprometê-las na medida em que sua participação é de mera indicação, sendo função do Consultor designado tratar toda a negociação, do início ao fim, oferecendo suporte técnico especializado."
          description2=""
            />
      </div>
      <div className="flex w-full z-0 pt-9 pb-9">
        <PPRCard2
          imageSrc="ppr_card4.png"
          mainTitle="Transparência na Gestão"
          description1="Ao cadastrar sua indicação, o(a) parceiro(a) recebe automaticamente um número de protocolo e a indicação do nome do Consultor responsável pelo processo de negociação da cota diretamente com o consorciado indicado. Assim, terá um comprovante de indicação e poderá acompanhar todo o desenrolar da negociação até o efetivo fechamento do negócio e pagamento da sua compensação financeira."
          description2="Dessa forma o(a) parceiro(a) poderá construir sua carteira de indicações com resultado financeiro satisfatório, ganho em escala e velocidade, principalmente porque o Consultor também é remunerado pelo fechamento do negócio, criando uma sinergia positiva no interesse de todos."
            />
      </div>
      <div className="flex w-full z-0">
        <Footer />
      </div>
    </section>
  );
}

export default ParticipacaoRemunerada;
