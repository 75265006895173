import React, { useEffect, useState } from 'react';
import './LeadContactForm.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputMask from 'react-input-mask';

function LeadContactForm({ credito, percentual, dataEncerramento, onCadastroSucesso }) {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [celular, setCelular] = useState('');
  const [utmParams, setUtmParams] = useState({});

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setUtmParams({
      utm_source: urlParams.get('utm_source') || '',
      utm_medium: urlParams.get('utm_medium') || '',
      utm_campaign: urlParams.get('utm_campaign') || '',
      utm_term: urlParams.get('utm_term') || '',
      utm_content: urlParams.get('utm_content') || '',
    });
  }, []);

  const notifySuccess = () => {
    toast.success('Cadastrado com sucesso!');
  };
  const notifyError = () => {
    toast.error('Erro ao cadastrar. Tente novamente.');
  };

  async function sendRDStation() {
    try {
      const response = await fetch('https://hook.us1.make.celonis.com/e5g5eh9bhgekskfekpri6ml13qybc6uw', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json' // Adicionado para garantir envio correto
        },
        body: JSON.stringify({ nome, email, celular, credito, percentual, dataEncerramento, ...utmParams }),
      });

      console.log('Response:', response);
      console.log('body:', JSON.stringify({ nome, email, celular, credito, percentual, dataEncerramento, ...utmParams }));
      if (response.ok) {
        notifySuccess();
        setNome('');
        setEmail('');
        setCelular('');
        onCadastroSucesso(); // Chama a função de callback após o sucesso
      } else {
        notifyError();
      }
    } catch (error) {
      console.error('Erro ao enviar:', error);
      notifyError();
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    sendRDStation();
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col rounded-[30px] px-5">
      <div className=''>
        <label htmlFor="nome" className='text-white normal-case pl-0 pb-0 block font-normal font-raleway'>Nome</label>
        <input
          type="text"
          id="nome"
          name='nome'
          value={nome}
          onChange={(event) => setNome(event.target.value)}
          className='w-full p-1 bg-white rounded-lg h-8'
          required
        />
      </div>

      <div className=''>
        <label htmlFor="celular" className='text-white normal-case pl-0 pb-0 block font-normal font-raleway'>Celular</label>
        <InputMask
          type="text"
          id="celular"
          name='celular'
          value={celular}
          onChange={(event) => setCelular(event.target.value)}
          className='w-full p-1 bg-white rounded-lg h-8'
          required
          placeholder='(99) 99999-9999'
          mask="(99) 99999-9999"
        />
      </div>
      <div className=''>
        <label htmlFor="email" className='text-white normal-case pl-0 pb-0 block font-normal font-raleway'>Email</label>
        <input
          type="email"
          id="email"
          name='email'
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          className='w-full p-1 bg-white rounded-lg h-8'
          required
          placeholder='email@email.com.br'
        />
      </div>
      <button
        className='mt-3 w-full rounded-lg text-sm border-none text-white cursor-pointer py-2'
        style={{ backgroundColor: '#004e80' }}
        type="submit">
        Quero vender meu <br />
        consórcio cancelado
      </button>
    </form>
  );
}

export default LeadContactForm;