import React from 'react';
import './UsAbout.css';
import 'react-toastify/dist/ReactToastify.css'

function UsAbout() {
    return (
        <>
            <div className='flex space-x-6 mx-auto my-auto h-sm:hidden'>
                <div className='flex h-full' style={{ width: '900px' }}>

                    <div style={{ width: '390px' }}>
                        <img src="us_About.png" alt="logo" />
                    </div>
                    <div style={{ width: '510px' }} className='p-5'>
                        <div>
                            <span style={{ color: '#f5be88' }}>Para você</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-3xl font-extrabold font-raleway'>Nossa Visão para o Futuro</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-sm'>A Objetiva busca se consolidar como referência na aquisição de direitos creditórios sobre cotas de consórcio canceladas, investindo fortemente em tecnologia e na formação de equipes de consultores. Esse investimento visa proporcionar um atendimento amplo e eficaz aos consorciados que desejam transformar suas cotas canceladas em dinheiro imediato.</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-sm'>Combinando tecnologia com um modelo de gestão escalável, desenvolvemos soluções abrangentes para atender toda a jornada digital dos nossos negócios, melhorando significativamente os resultados. Estamos à frente no mercado secundário do sistema de consórcio, direcionando nossos esforços e planejando o futuro com uma visão inovadora.</span>
                        </div>

                    </div>
                </div>
            </div>
            {/* Componente responsivo para celular */}
            <div className='flex mx-auto my-auto h-sm:flex hidden px-4'>
                <div className='h-full w-full'>
                    <div className='w-full h-full'>
                        <img src="us_About-mobile.png" alt="logo" className='w-full' />
                    </div>
                    <div className='w-full h-full mt-2'>
                        <div>
                            <span style={{ color: '#f5be88' }}>Para você</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-3xl font-extrabold font-raleway'>Quanto você ganha?</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-sm'>A Objetiva busca se consolidar como referência na aquisição de direitos creditórios sobre cotas de consórcio canceladas, investindo fortemente em tecnologia e na formação de equipes de consultores. Esse investimento visa proporcionar um atendimento amplo e eficaz aos consorciados que desejam transformar suas cotas canceladas em dinheiro imediato.</span>
                        </div>
                        <div className='mt-2'>
                        <span className='text-sm'>Combinando tecnologia com um modelo de gestão escalável, desenvolvemos soluções abrangentes para atender toda a jornada digital dos nossos negócios, melhorando significativamente os resultados. Estamos à frente no mercado secundário do sistema de consórcio, direcionando nossos esforços e planejando o futuro com uma visão inovadora.</span>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default UsAbout;