import React from 'react';
import './UsWeDo.css';
import 'react-toastify/dist/ReactToastify.css'

function UsWeDo() {
    return (
        <>
            <div className='flex space-x-6 mx-auto my-auto h-sm:hidden'>
                <div className='flex h-full' style={{ width: '900px' }}>
                    <div style={{ width: '510px' }} className='p-5'>
                        <div>
                            <span style={{ color: '#f5be88' }}>Para você</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-3xl font-extrabold font-raleway'>Nosso diferencial</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-sm'>A Objetiva não apenas segue as tendências do mercado, mas as antecipa, estando sempre um passo à frente. Essa visão proativa e o compromisso em oferecer soluções completas e eficazes nos fazem uma escolha confiável e diferenciada no mercado de consórcios. Nossa dedicação em entender e atender às necessidades dos clientes é o que realmente nos destaca.</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-sm'>Investimos constantemente na formação de equipes de consultores e na melhoria contínua de nossos processos, com o objetivo de transformar as cotas canceladas em dinheiro imediato para nossos clientes, consolidando nossa posição como referência no setor.</span>
                        </div>

                    </div>
                    <div style={{ width: '390px' }} >
                        <img src="us_WeDo.png" alt="logo" />
                    </div>
                </div>
            </div>
            {/* Componente responsivo para celular */}
            <div className='flex mx-auto my-auto h-sm:flex hidden px-4'>
                <div className='h-full w-full'>
                    <div className='w-full h-full' >
                        <img src="us_WeDo-mobile.png" alt="logo" className='w-full h-full' />
                    </div>
                    <div className='w-full h-full mt-2'>
                        <div>
                            <span style={{ color: '#f5be88' }}>Para você</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-3xl font-extrabold font-raleway'>Nosso diferencial</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-sm'>A Objetiva não apenas segue as tendências do mercado, mas as antecipa, estando sempre um passo à frente. Essa visão proativa e o compromisso em oferecer soluções completas e eficazes nos fazem uma escolha confiável e diferenciada no mercado de consórcios. Nossa dedicação em entender e atender às necessidades dos clientes é o que realmente nos destaca.</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-sm'>Investimos constantemente na formação de equipes de consultores e na melhoria contínua de nossos processos, com o objetivo de transformar as cotas canceladas em dinheiro imediato para nossos clientes, consolidando nossa posição como referência no setor.</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UsWeDo;