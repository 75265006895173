import React, { useState } from 'react';
import './PasswordPPR.css';
import { notifyInfoMail, notifyPassword } from '../../utils/notify';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function PasswordPPR({ isOpen, closeModal }) {
  const [email, setEmail] = useState('');

  const validationEmail = () => {
    const charactersEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!charactersEmail.test(email)) {
      var input = "Por favor, insira um endereço de e-mail válido.";
      notifyPassword(input);
      return false;
    }
    return true;
  }

  const checksInput = (event) => {
    event.preventDefault();
    if (validationEmail()) {
      notifyInfoMail();
      setTimeout(function () {
        setEmail('');
        closeModal();
      }, 2500);
    };

  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Register Modal"
      className='absolute inset-10 h-sm:inset-0 border-gray-300 bg-white overflow-auto outline-none p-5 rounded-2xl w-1/3 h-sm:w-full mx-auto mt-20'
      shouldCloseOnOverlayClick={true}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
        },
        content: {
          zIndex: 1001,
          height: '400px',
          backgroundColor: '#004e80'
        },
      }}
    >
      <div className='w-full h-auto flex justify-end'>
        <button className='rounded-full h-9 w-9 text-white font-bold' onClick={closeModal} style={{ border: '4px solid white' }}>X</button>
      </div>
      <form className='text-center px-10 space-y-4 h-sm:px-5'>
        <h1 className='text-white text-2xl mb-5'>Esqueceu sua senha?</h1>
        <div className='pb-5'>
          <span className='text-white text-base'>Será enviado um e-mail para sua conta onde será dado um passo a <br />
            passo para redefinir de maneira segura e pratica.</span>
          <br />
          <span className='text-white text-base'>Seguiu todos passos? Basta realizar o login!</span>
        </div>
        <input type='email' value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" className='w-full bg-slate-200 rounded-lg h-10 p-3 font-normal' id='new_password' name='new_password' required></input>
        <button className='text-white rounded-lg border-0 w-full h-10 text-base cursor-pointer' style={{ backgroundColor: '#c76f36' }} onClick={checksInput}>Redefinir</button>
        <p className='return-to-login' onClick={closeModal}>Voltar ao login</p>
      </form>
    </Modal>
  );
}

export default PasswordPPR;
