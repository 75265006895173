import React from 'react';
import './UsHowMuch.css';
import 'react-toastify/dist/ReactToastify.css'

function UsHowMuch() {
    return (
        <>
            <div className='flex space-x-6 mx-auto my-auto h-sm:hidden'>
                <div className='flex h-full' style={{ width: '900px' }}>

                    <div style={{ width: '390px' }}>
                        <img src="us_howMuch.png" alt="logo" />
                    </div>
                    <div style={{ width: '510px' }} className='p-5'>
                        <div>
                            <span style={{ color: '#f5be88' }}>Para você</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-3xl font-extrabold font-raleway'>Nossa História</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-sm'>A Objetiva nasceu em 18 de novembro de 2010 como uma startup inovadora, dedicada à aquisição de direitos creditórios de consorciados desistentes ou excluídos. Facilitamos esse processo com uma calculadora acessível em nosso site institucional.</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-sm'>Após anos de trabalho e resiliência, nos destacamos pelo valor justo na compra de cotas canceladas e pela quantidade de acordos firmados com as maiores administradoras de consórcio do mercado.</span>
                        </div>
                    </div>
                </div>
            </div>
            {/* Componente responsivo para celular */}
            <div className='flex mx-auto my-auto h-sm:flex hidden px-4'>
                <div className='h-full w-full'>

                    <div className='w-full h-full'>
                        <img src="us_howMuch-mobile.png" alt="logo" className='w-full' />
                    </div>
                    <div className='w-full h-full mt-2'>
                        <div>
                            <span style={{ color: '#f5be88' }}>Para você</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-3xl font-extrabold font-raleway'>Nossa História</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-sm'>A Objetiva nasceu em 18 de novembro de 2010 como uma startup inovadora, dedicada à aquisição de direitos creditórios de consorciados desistentes ou excluídos. Facilitamos esse processo com uma calculadora acessível em nosso site institucional.</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-sm'>Após anos de trabalho e resiliência, nos destacamos pelo valor justo na compra de cotas canceladas e pela quantidade de acordos firmados com as maiores administradoras de consórcio do mercado.</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UsHowMuch;