import React, { useState } from 'react';
import './RegisterPPR.css';
import InputMask from 'react-input-mask';
import { newReferrer } from '../../utils/api';
import { notify, notifyPassword } from '../../utils/notify';
import { toast } from 'react-toastify';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function RegisterPPR({ isOpen, closeModal }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [status, setStatus] = useState('A');

  const checksInput = () => {
    if (name === '') {
      var input = "nome";
      notify(input);
      return false;
    } else if (phone === '') {
      var input = "telefone";
      notify(input);
      return false;
    } else if (email === '') {
      var input = "email";
      notify(input);
      return false;
    } else if (!validationEmail()) {
      return false;
    } else if (password === '') {
      var input = "senha";
      notify(input);
      return false;
    } else if (password !== passwordConfirm) {
      var err = "As senhas não coincidem";
      notifyPassword(err);
      return false;
    }
    return true;
  }

  const validationEmail = () => {
    const charactersEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!charactersEmail.test(email)) {
      var input = "Por favor, insira um endereço de e-mail válido.";
      notifyPassword(input);
      return false;
    }
    return true;
  }

  const cleanFields = () => {
    setName('');
    setEmail('');
    setPhone('');
    setPassword('');
    setPasswordConfirm('');
  }

  const RegisterReferrers = async (event) => {
    event.preventDefault();

    if (!checksInput()) {
      return false;
    }

    const data = {
      name: name,
      email: email,
      phonenumber: phone,
      password: password,
      status: status,
    };

    const id = toast.loading('Aguarde...', { duration: null });

    try {
      const response = await newReferrer(data);

      if (response) {
        if (response.status === 409) {
          toast.update(id, { render: 'E-mail já cadastrado!', type: 'info', isLoading: false, autoClose: 3000 });
        } else if (response.status === 201) {
          toast.update(id, { render: 'Cadastro realizado com sucesso!', type: 'success', isLoading: false, autoClose: 3000 });

          setTimeout(() => {
            toast.dismiss(1);
            closeModal();
            cleanFields();
          }, 3000);
        }
      }
    } catch (err) {
      console.log(err);
      toast.update(id, { render: 'Erro ao cadastrar', type: 'error', isLoading: false });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Register Modal"
      className='absolute inset-10 h-sm:inset-0 border-gray-300 bg-white overflow-auto outline-none p-5 rounded-2xl w-1/3 h-sm:w-full mx-auto mt-20'
      shouldCloseOnOverlayClick={true}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
        },
        content: {
          zIndex: 1001,
          height: '400px',
          backgroundColor: '#004e80'
        },
      }}
    >
      <div className='w-full h-auto flex justify-end'>
        <button className='rounded-full h-9 w-9 text-white font-bold' onClick={closeModal} style={{ border: '4px solid white' }}>X</button>
      </div>
      <form className='px-10 space-y-4 h-sm:px-5'>
        <h1 className='text-2xl text-center m-0 text-white'>Cadastre-se </h1>
        <input type='text' value={name} onChange={e => setName(e.target.value)} placeholder="Nome" className='w-full bg-slate-200 rounded-lg h-10 p-3 font-normal' id='user_name' name='user_name' /><br />
        <InputMask type='text' value={phone} onChange={e => setPhone(e.target.value)} mask="(99) 99999-9999" placeholder="Telefone" className='w-full bg-slate-200 rounded-lg h-10 p-3 font-normal' id='user_phone' name='user_phone' /><br />
        <input type='text' value={email} onChange={e => setEmail(e.target.value)} placeholder="Email para login" className='w-full bg-slate-200 rounded-lg h-10 p-3 font-normal' id='user_mail' name='user_mail' /><br />
        <div className='flex space-x-4'>
          <div className='w-1/2'>
            <input type='password' value={password} onChange={e => setPassword(e.target.value)} placeholder="Senha" className='w-full bg-slate-200 rounded-lg h-10 p-3 font-normal' id='user_password' name='user_password' />
          </div>
          <div className='w-1/2'>
            <input type='password' value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)} placeholder="Senha" className='w-full bg-slate-200 rounded-lg h-10 p-3 font-normal' id='user_password_confirm' name='user_password_confirm' />
          </div>
        </div>
        <div className='flex justify-center items-center'>
          <button className='text-white rounded-lg border-0 w-full h-10 text-base cursor-pointer' style={{ backgroundColor: '#c76f36' }} onClick={RegisterReferrers}>Cadastre-se</button>
        </div>
      </form>
    </Modal>
  );
}

export default RegisterPPR;
