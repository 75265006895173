import React, { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import calcularResultado from '../../utils/calculosV3';
import './Calculadora.css';
import LeadContactForm from '../LeadContactForm/LeadContactForm';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

function Calculadora() {
  const [credito, setCredito] = useState('');
  const [percentual, setPercentual] = useState('');
  const [dataEncerramento, setDataEncerramento] = useState('');
  const [isGirando, setIsGirando] = useState(1); // 1: Calculadora, 2: Resultado, 3: Cadastro
  const [resultado, setResultado] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showFailMessage, setShowFailMessage] = useState(false);

  const formatDate = (date) => {
    const d = new Date(date),
      day = '' + d.getDate(),
      month = '' + (d.getMonth() + 1),
      year = d.getFullYear();

    return [day.length < 2 ? '0' + day : day,
    month.length < 2 ? '0' + month : month,
      year].join('/');
  };

  const handleCalcular = (event) => {
    event.preventDefault();

    let resultadoCalculo = calcularResultado(credito, percentual, dataEncerramento);

    if (!credito || !percentual || !dataEncerramento) {
      setShowErrorMessage(true);
      setShowFailMessage(false);
      setResultado(null);
      setIsGirando(1);
      return;
    } else {
      if (!isNaN(resultadoCalculo)) {
        resultadoCalculo = parseFloat(resultadoCalculo);

        if (resultadoCalculo === 0) {
          setShowFailMessage(true);
          setShowErrorMessage(false);
          setResultado(null);
          setIsGirando(1);
          return;
        } else {
          const resultadoArredondado = parseFloat(resultadoCalculo).toFixed(2);
          setResultado(resultadoArredondado);
          setIsGirando(2); // Mostra o resultado
        }

      } else {
        setResultado(null);
      }
    }
  };

  const handleVoltar = () => {
    setIsGirando(1); // Volta para a calculadora
    setShowErrorMessage(false);
    setShowFailMessage(false);
    setResultado(null);
    setCredito('');
    setPercentual('');
    setDataEncerramento('');
  };

  const handleCadastroSucesso = () => {
    setIsGirando(3); // Mostra a tela de cadastro feito com sucesso
  };


  return (
    <div className='p-4 rounded-lg shadow-xl w-96 h-400 h-lg:h-450 h-lg:w-500 h-lg:p-7 h-sm:w-96' style={{ backgroundColor: '#c76f36' }}>
      {isGirando === 1 && (
        <div className="frente">
          <div className='flex justify-center space-x-8'>
            <div className='w-2 h-2 rounded-full' style={{ backgroundColor: '#004e80' }}></div>
            <div className='w-2 h-2 rounded-full' style={{ backgroundColor: '#f5be88' }}></div>
            <div className='w-2 h-2 rounded-full' style={{ backgroundColor: '#f5be88' }}></div>
          </div>

          <div className='h-12'>
            <h3 className='text-center normal-case text-blue-900 font-sans mb-1 mt-1 text-xl font-raleway' style={{ color: '#f5be88' }}>Nossa Proposta</h3>

            {showErrorMessage &&
              <div className='text-center'>
                <div className='text-xs text-white'>Por favor, preencha todos os campos corretamente!</div>
              </div>
            }
            {showFailMessage &&
              <div className='text-center'>
                <div className="text-xs text-white">Valores fora dos nossos parâmetros de proposta</div>
              </div>
            }
          </div>
          <form className='px-5'>
            <div className='mb-1'>
              <label className='text-white normal-case pl-0 pb-0 block font-normal font-raleway'>Crédito</label>
              <span className='text-white normal-case text-xs p-1 pl-0 pt-0 block font-normal font-raleway'>Valor do bem</span>
              <CurrencyInput
                intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                decimalsLimit={2}
                groupSeparator="."
                name='credito'
                value={credito}
                onValueChange={(value, name) => setCredito(value)}
                className='w-full p-1 bg-white rounded-lg h-8'
              />
            </div>
            <div className='mb-1'>
              <label className='text-white normal-case pl-0 pb-0 block font-normal font-raleway'>Percentual Pago</label>
              <span className='text-white normal-case text-xs p-1 pl-0 pt-0 block font-normal font-raleway'>Valor pago em percentual do fundo comum</span>
              <CurrencyInput
                suffix="%"
                allowDecimals={true}
                decimalSeparator=","
                groupSeparator="."
                allowNegativeValue={false}
                name='percentual'
                value={percentual}
                decimalScale={4}
                decimalsLimit={4}
                maxLength={7}
                fixedDecimalLength={4}
                format="##,####"
                onValueChange={(value, name) => setPercentual(value)}
                className='w-full p-1 bg-white rounded-lg h-8'
              />
            </div>
            <div className=''>
              <label className='text-white normal-case pl-0 pb-0 block font-normal font-raleway'>Encerramento do Grupo</label>
              <span className='text-white normal-case text-xs p-1 pl-0 pt-0 block font-normal font-raleway'>Faltando até 10 anos para encerrar o grupo</span>
              <input
                type="date"
                name='dataEncerramento'
                value={dataEncerramento}
                onChange={(event) => setDataEncerramento(event.target.value)}
                className='w-full p-1 bg-white rounded-lg h-8'
              />
            </div>
            <button
              className='mt-3 h-10 w-full rounded-lg text-sm border-none text-white cursor-pointer'
              style={{ backgroundColor: '#004e80' }}
              type="submit"
              onClick={handleCalcular}>
              Calcular
            </button>
          </form>
        </div>
      )}

      {isGirando === 2 && (
        <div>
          <div className='flex justify-center items-center space-x-8 relative'>
            <div className='w-2 h-2 rounded-full' style={{ backgroundColor: '#f5be88' }}></div>
            <div className='w-2 h-2 rounded-full' style={{ backgroundColor: '#004e80' }}></div>
            <div className='w-2 h-2 rounded-full' style={{ backgroundColor: '#f5be88' }}></div>
            <div className='absolute right-0'>
              <button
                style={{ backgroundColor: '#004e80' }}
                className='text-white text-xs h-7 w-8 flex items-center justify-center text-center mt-0 rounded-full'
                onClick={handleVoltar}
              >
                ↩
              </button>
            </div>
          </div>
          <h3 className='text-center normal-case text-blue-900 font-sans mb-1 mt-1 text-xl font-raleway' style={{ color: '#f5be88' }}>Confira nossa proposta</h3>
          <p className="text-3xl font-bold text-white mt-0 mb-1 text-center">{resultado ? parseFloat(resultado).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '0,00'}</p>
          <div className='justify-center flex'>
            <div className='w-52 mt-4'>
              <p className='text-xs text-white mt-0 mb-0 p-0'>Data da proposta: {formatDate(new Date())}</p>
              <p className='text-xs text-white mt-0 mb-0 p-0'>Crédito: {credito ? parseFloat(credito).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '0,00'}</p>
              <p className='text-xs text-white mt-0 mb-0 p-0'> Percentual pago: {percentual ? percentual : 0}%</p>
              <p className='text-xs text-white mt-0 mb-0 p-0'>Encerramento do grupo: {dataEncerramento ? format(parseISO(dataEncerramento), 'dd/MM/yyyy', { locale: ptBR }) : '00/00/0000'}</p>
            </div>
          </div>
          <LeadContactForm
            credito={credito}
            dataEncerramento={dataEncerramento}
            percentual={percentual}
            onCadastroSucesso={handleCadastroSucesso}
          />
        </div>
      )}

      {isGirando === 3 && (
        <div>
          <div className='flex justify-center items-center space-x-8 relative'>
            <div className='w-2 h-2 rounded-full' style={{ backgroundColor: '#f5be88' }}></div>
            <div className='w-2 h-2 rounded-full' style={{ backgroundColor: '#f5be88' }}></div>
            <div className='w-2 h-2 rounded-full' style={{ backgroundColor: '#004e80' }}></div>
          </div>
          <h3 className='text-center normal-case text-blue-900 font-sans mb-1 mt-1 text-xl font-raleway' style={{ color: '#f5be88' }}>Obrigado!</h3>
          <p className="text-3xl font-bold text-white mt-0 mb-1 text-center mt-32">Cadastro feito com sucesso!</p>
          <h3 className='text-center normal-case text-blue-900 font-sans mb-1 mt-1 text-xl font-raleway' style={{ color: '#f5be88' }}>Em breve entraremos em contato</h3>
          <div className='w-full h-full text-center'>
            <button
              className='mt-3 w-2/3 rounded-lg text-sm border-none text-white cursor-pointer py-2 mt-28'
              style={{ backgroundColor: '#004e80' }}
              onClick={handleVoltar}>
              Voltar ao inicio
            </button>
          </div>

        </div>
      )}
    </div>
  );
}

export default Calculadora;